/**
 * PlanBundlePage tests
 */
// Mobile sticky footer CTA
export const MOBILE_STICKY_TEST = {
  NAME: 'full-mobile-sticky',
  VARIANT: 'full-sticky-flow',
};

// Allow for PDP modal to open in bundle shipment items
export const PDP_MODAL_TEST = {
  NAME: 'abtests-138-psp-product-details-modal-v3',
  SHOW_DETAIL_MODAL_VARIANT: 'show-product-details-modal',
};

// Show Smart Badges from quiz answers with bundles
export const SMART_BADGES_TEST = {
  NAME: 'abtests-186-psp-smart-badges',
  SHOW_BADGES_VARIANT: 'show-badges',
};

// Hardmark pricing test for lawn bundles
export const HARDMARK_TEST = {
  NAME: 'hardmark-bundle-pricing-196',
  HARDMARK_VARIANT: 'show-hardmark-price',
};

// Modal to update lawn size
export const LAWN_SIZE_TEST = {
  NAME: 'abtests-115-psp-lawn-size',
  LAWN_SIZE_VARIANT: 'update-lawn-size',
};

// Test alternate copy in shipment product tooltips
export const PRODUCT_TOOLTIP_TEST = {
  NAME: 'abtests-131-psp-product-tooltip',
  TOOLTIP_COPY_VARIANT: 'alt-product-tooltip',
};

export const NEED_HELP_FOOTER_TEST = {
  NAME: 'abtests-140-need-help-footer-psp',
  SHOW_NEED_HELP_VARIANT: 'show-need-help',
};

// Lawn application timeline graphic for user
export const APPLICATION_TIMELINE_TEST = {
  NAME: 'abtests-180-lawn-app-timeline',
  LAWN_TIMELINE_VARIANT: 'lawn-funnel-application-timeline',
  YARD_TIMELINE_VARIANT: 'yard-funnel-application-timeline',
};

// Show box descriptions on Lawn PSP
export const LAWN_PSP_BOX_DESCRIPTIONS_TEST = {
  NAME: 'abtests-184-lawn-psp-box-descriptions',
  SHOW_DESCRIPTIONS_LAWN: 'show-lawn-psp-box-descriptions-lawn-funnel',
  SHOW_DESCRIPTIONS_YARD: 'show-lawn-psp-box-descriptions-yard-funnel',
};

export const LAWN_PSP_HIDE_HERB_POUCH_SPRAYER_TEST = {
  NAME: 'abtests-196-hide-herb-pouch-sprayer',
  HIDE_SPRAYER_VARIANT: 'hide-herb-pouch-sprayer',
};

/**
 * Yard Funnel Tests
 */
export const FUNNEL_STEP_REORDER_TEST = {
  NAME: 'abtests-193-funnel-step-reorder-v2',
  REORDERED_YARD_FUNNEL_VARIANT: 'reordered-yard-funnel',
};

// Show Weed Warrior product opt-in page in funnel and widget on PSP
export const WEED_WARRIOR_FUNNEL_TEST = {
  NAME: 'abtests-185-weed-warrior-funnel-v2',
  FUNNEL_PSP_VARIANT: 'ww-funnel-psp',
  PSP_ONLY_VARIANT: 'ww-psp-only',
  YARD_FUNNEL_PSP_VARIANT: 'ww-yard-funnel-psp',
};

// Show alternate copy on FunnelAuthModal to save email
export const FUNNEL_AUTH_MODAL_COPY_TEST = {
  NAME: 'abtests-183-funnel-auth-modal-copy',
  FREE_SHIPPING_VARIANT: 'free-shipping-copy',
  SEND_RESULTS_VARIANT: 'send-results-copy',
};

// Add seed question and modify seed interstitial page based on answer
export const GRASS_FUNNEL_TEST = {
  NAME: 'abtests-189-grass-type-funnel',
  GRASS_QUESTION_VARIANT: 'grass-question',
};

// Hide product interstitial pages in quiz
export const QUIZ_HIGHLIGHTS_TEST = {
  NAME: 'abtests-187-lawn-quiz-highlights-pages-v2',
  HIDE_HIGHLIGHTS_VARIANT: 'hide-highlights-pages',
};

// Skip pest and garden continuation pages in yard funnel
export const YARD_CONTINUATION_TEST = {
  NAME: 'abtests-194-yard-continuation-pages',
  SKIP_PAGES_VARIANT: 'skip-pages',
};

/**
 * SmartLawnPlanPage - AddressForm
 */
// Tooltip for why we ask for user's address
export const WHY_TOOLTIP_TEST = {
  NAME: 'why-do-we-ask-tooltip',
  SHOW_TOOLTIP_VARIANT: 'show-tooltip',
};

/**
 * ProductDetailsPage
 */
// Chatbot help
export const PDP_CHAT_BOT_TEST = {
  NAME: 'pdp-chat-bot',
  SHOW_CHATBOT_VARIANT: 'show-chat-bot',
};

/**
 * CartPage
 */
// Hide mobile sticky CTA
export const HIDE_MOBILE_CTA_TEST = {
  NAME: 'abtests-105-mobile-cta-cart',
  HIDE_CTA_VARIANT: 'hide-mobile-cta',
};

export const SEED_FINDER_ZIP_CODE_TEST = {
  NAME: 'abtests-144-seed-finder-zip-code',
  ZIP_CODE_VARIANT: 'zip-code',
};

// Show product add-ons on cart page, skip FunnelProductsPage
export const CART_ADD_ONS_TEST = {
  NAME: 'abtests-160-funnel-cart-add-ons',
  SHOW_ADD_ONS_VARIANT: 'cart-add-ons',
};

export const MULTI_PAYMENT_TYPE_TEST = {
  NAME: 'abtests-174-multi-payment-type',
  MULTI_TOGGLE_VARIANT: 'multi-payment-toggles',
};

/**
 * Home page
 */
// Email sign up in footer
export const FOOTER_EMAIL_SIGN_UP_TEST = {
  NAME: 'abtests-176-footer-email-signup',
  FOOTER_EMAIL_INPUT_VARIANT: 'footer-email-input',
};
